import { combineReducers } from 'redux';

import routing from './routing';
import banner from './banner';
import news from './news';
import posts from './post';
import tags from './tag';
import toast from './toast';
import partner from './partner';
import members from './member';
import blogs from './blog';
import legalPost from './legalMap';
import events from './events';
import registration from './registration';
import contact from './contact';

const reducers = combineReducers({
	...routing.reducer,
	...banner.reducer,
	...news.reducer,
	...posts.reducer,
	...tags.reducer,
	...toast.reducer,
	...partner.reducer,
	...members.reducer,
	...blogs.reducer,
	...legalPost.reducer,
	...events.reducer,
	...registration.reducer,
	...contact.reducer,
});

export default reducers;
